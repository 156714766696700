import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../ENDPOINT';
import { prepareHeaders } from '../Header';
import _ from 'lodash';

export const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getFavorites: builder.query({
      query: () => ({
        url: '/favorites',
        method: 'GET',
      }),
      transformResponse: async (response) => {
        try {
          let finalResponse = response.map((data) => {
            return {
              ...data,
              company: data.comp_name,
            };
          });

          const sortedResponse = _.orderBy(
            finalResponse,
            ['initial_date'],
            ['desc']
          );
          return sortedResponse;
        } catch (error) {
          console.error(error);
        }
      },
      onError: (error, query) => {
        console.error('An error occurred while fetching favorites:', error);
      },
    }),
  }),
});

export const { useGetFavoritesQuery } = favoritesApi;
