import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading";
import {
  generateCodeVerifier,
  generateCodeChallenge,
} from "../../helpers/config/Auth/pkce";
import { isJwtExpired } from "../../helpers/utils/validation";
import config from "../../config";
import Header from "./Header";
import Content from "./Content";
import { Box, Typography } from "@mui/material";
import ZohoFormEmbed from "./ZohoForm";

export default function Index() {
  const [loading, setLoading] = useState(2);
  const [form, setForm] = useState(false);
  const navigate = useNavigate();
  const { CLIENT_ID, LOGIN_BASE_URL, HOSTED_URL } = config;

  const onClickLoginHandler = async () => {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    localStorage.setItem("codeVerifier", codeVerifier);
    const signInUrl = `${LOGIN_BASE_URL}/oauth2/v2.0/authorize?p=B2C_1_signin&client_id=${CLIENT_ID}&nonce=defaultNonce&redirect_uri=${HOSTED_URL}&scope=openid&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=${codeChallenge}`;
    window.location.href = signInUrl;
  };

  const redirectToHomePage = () => {
    navigate("/results");
  };

  const handleGetStarted = () => setForm(() => true);

  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    const verifier = localStorage?.getItem("codeVerifier");
    const accessToken = localStorage?.getItem("access_token");

    if (accessToken && !isJwtExpired(accessToken)) {
      return redirectToHomePage();
    }

    if (!code || !verifier) {
      return setLoading(1);
    }

    const requestBody =
      "grant_type=authorization_code" +
      "&client_id=" +
      CLIENT_ID +
      "&code_verifier=" +
      verifier +
      "&code=" +
      code;

    const initializeData = async () => {
      try {
        const { data } = await axios.post(
          `${LOGIN_BASE_URL}/B2C_1_signin/oauth2/v2.0/token`,
          requestBody
        );

        localStorage.setItem("access_token", data.id_token);
        localStorage.removeItem("codeVerifier");

        // Remove the code from the URL
        url.searchParams.delete("code");
        window.history.replaceState({}, document.title, url.pathname);

        redirectToHomePage();
      } catch (error) {
        setLoading(0);
        return error;
      }
    };

    initializeData();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {loading === 1 && (
        <Box className="flex flex-col h-screen w-full">
          <Header
            backToLogin={setForm}
            form={form}
            onClick={onClickLoginHandler}
          />
          {form ? (
            <Box className="flex flex-col justify-center items-center px-4">
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: 600,
                }}
              >
                Request An Invite
              </Typography>
              <Typography
                variant="paragraph"
                className="text-grey-100 text-center"
              >
                Thank you for your interest! Please fill out the form below to
                request an invite code/link.
              </Typography>

              <ZohoFormEmbed />
            </Box>
          ) : (
            <Content handleGetStarted={handleGetStarted} />
          )}
        </Box>
      )}
      {loading === 2 && <Loading />}
    </>
  );
}
