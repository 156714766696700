import SearchTerms from './SearchTerms';
import { useGetSearchTermsQuery } from '../../features/searchTerms';
import Loading from '../Common/Loading';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function SearchTermsComponent() {
	const { isLoading, isError } = useGetSearchTermsQuery();
	const navigate = useNavigate();
	const accessToken = localStorage?.getItem('access_token');

	useEffect(() => {
		if (!accessToken) {
			// Redirect to landing page if token is missing
			navigate('/');
		}
	}, [accessToken, navigate]);

	return (
		<>
			<Box className="flex flex-col items-center h-full w-full mx-4 xl:mx-[32px] mt-[32px]">
				{isLoading && <Loading />}
				{!isLoading && !isError && <SearchTerms />}
			</Box>
		</>
	);
}
