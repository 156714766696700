import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../ENDPOINT';
import { prepareHeaders } from '../Header';

export const descriptionsApi = createApi({
	reducerPath: 'descriptionsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrl,
		prepareHeaders
	}),
	endpoints: (builder) => ({
		getDescriptions: builder.query({
			query: (mongo_id) => ({
				url: `/descriptions/${mongo_id}`,
				method: 'GET'
			}),
			transformResponse: async (response) => {
				try {
					return response;
				} catch (error) {
					console.error(error);
				}
			},
			onError: (error, query) => {
				console.error('An error occurred while fetching descriptions:', error);
			}
		})
	})
});

export const useGetDescriptionsQuery = (mongo_id) => {
	return descriptionsApi.useGetDescriptionsQuery(mongo_id);
};
