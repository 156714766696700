import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { InputText } from '../../UI/Input';
import Button from '../../UI/Button';
import { useState, useEffect } from 'react';
import Notification from 'components/Common/Notification';
import { WrapperButtonIcon } from '../../UI/Wrapper';
import { SetNotes } from 'features/api/Company';

export default function NotesDialog({
	handleCloseNotesDialog,
	editMode,
    setNotesMain,
    setIsNotesEmpty,
	comp_notes,
    comp_id
}) {
	const [notes, setNotes] = useState('');
	const [adding, setAdding] = useState(false);
	const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
	const [notification, setNotification] = useState({
		message: '',
		type: '',
		open: false
	});

	const handleSave = async () => {
        setSaving(true);

        try {
            const cleanedNotes = notes.trim();
            await SetNotes({ comp_id, notes:cleanedNotes });
            
            setSaving(false);
            setNotesMain(cleanedNotes);
            setIsNotesEmpty((!notes || (notes === "")) ? true : false);
            handleCloseNotesDialog();
        } catch (error) {
            setNotification({
                message: `${error.response?.data ? error.response?.data : error}`,
                type: 'error',
                open: true
            });

            setSaving(false);
        }
	};

    const handleAdd = async () => {
        setAdding(true);

        try {
            const cleanedNotes = notes.trim();
            await SetNotes({ comp_id, notes:cleanedNotes });
            
            setAdding(false);
            setNotesMain(cleanedNotes);
            setIsNotesEmpty((!notes || (notes === "")) ? true : false);
            handleCloseNotesDialog();
        } catch (error) {
            setNotification({
                message: `${error.response?.data ? error.response?.data : error}`,
                type: 'error',
                open: true
            });
            
            setAdding(false);
        }
    }

    const handleDelete = async () => {
        setDeleting(true);

        try {
            await SetNotes({ comp_id, notes:null });
            
            setDeleting(false);
            setNotesMain("");
            setIsNotesEmpty(true);
            handleCloseNotesDialog();
        } catch (error) {
            setNotification({
                message: `${error.response?.data ? error.response?.data : error}`,
                type: 'error',
                open: true
            });

            setDeleting(false);
        }
    }

    useEffect(() => {
        setNotes(comp_notes);
    }, [comp_notes]);

	return (
		<Box className="fixed z-10 inset-0 overflow-y-auto">
			<Notification
				message={notification.message}
				type={notification.type}
				open={notification.open}
				setOpen={setNotification}
			/>
			<Box className="flex items-center justify-center min-h-screen">
				<Box
					className="fixed z-10 inset-0 bg-black-100 bg-opacity-50 transition-opacity"
					aria-hidden="true"
				></Box>
				<Box className="z-30 bg-white-100 relative p-8 shadow-xl w-[65%]">
					<Box className="flex justify-between items-center mb-8">
						<Typography variant="title2">{editMode ? "Notes" : "Add Notes"}</Typography>
						<WrapperButtonIcon className="cursor-default" onClick={handleCloseNotesDialog}>
							<FontAwesomeIcon
								icon={faXmark}
								style={{
									cursor: 'pointer',
									color: '#71717A',
									fontSize: '32px'
								}}
							/>
						</WrapperButtonIcon>
					</Box>
					<Box className="flex flex-col">
						<Box className="flex flex-col gap-y-[16px] mb-8">
							<Box>
								<InputText
									className="h-auto"
                                    placeholder="Insert text here"
									required={true}
									value={notes}
                                    multiline
									onChange={(event) => {
										setNotes(event.target.value);
									}}
								/>
							</Box>
						</Box>
						<Box className="flex justify-center gap-5">
                            { editMode ? (
                                <>
                                    <Button
                                        className="text-[16px] xl:text-[18px]"
                                        label={deleting ? 'Deleting...' : 'Delete'}
                                        variant="secondary"
                                        onClick={handleDelete}
                                        disabled={deleting}
                                    />
                                    <Button
                                        className="text-white-100 text-[16px] xl:text-[18px]"
                                        label={saving ? 'Saving...' : 'Save'}
                                        variant="primary"
                                        onClick={handleSave}
                                        disabled={saving}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        className="text-[16px] xl:text-[18px]"
                                        label='Cancel'
                                        variant="secondary"
                                        onClick={handleCloseNotesDialog}
                                    />
                                    <Button
                                        className="text-white-100 text-[16px] xl:text-[18px]"
                                        label={adding ? 'Adding' : 'Add'}
                                        variant="primary"
                                        onClick={handleAdd}
                                        disabled={adding}
                                    />
                                </>
                            )}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
