import { Button, ButtonGroup, useTheme, Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { WrapperButtonIcon } from './Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faBan } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarked } from '@fortawesome/free-regular-svg-icons';

export default function CustomButton({
	TypographyComponent,
	typographyProps,
	label,
	width,
	...props
}) {
	return (
		<Button
			{...props}
			sx={{ width: width ? width : 'auto', height: '100%', textTransform: 'none' }}
		>
			{TypographyComponent ? (
				<TypographyComponent {...typographyProps}> {label}</TypographyComponent>
			) : (
				label
			)}
		</Button>
	);
}

export const NotesButton = ({ className, label, notes, ...props }) => {
	return (
		<Box
			className={`${className} px-[16px] py-[12px] rounded-md flex justify-left items-center h-auto w-[600px] bg-grey-900 cursor-pointer`}
		>
			<Typography className="font-medium text-[16] mr-[8px]">{label}</Typography>
			<Typography noWrap type="text" {...props}>
				{notes}
			</Typography>
		</Box>
	);
};

export const FavoritesIgnoresButton = ({
	className,
	favoritesFilter,
	ignoresFilter,
	handleFavoritesClick,
	handleIgnoresClick,
	...props
}) => {
	// const theme = useTheme();

	return (
		<ButtonGroup
			className={`${className} rounded-md flex items-center h-auto w-auto cursor-pointer`}
		>
			<Box
				className={`rounded-l-md ${
					favoritesFilter
						? 'border-2 bg-purple-100 border-purple-100'
						: 'border-l-2 border-y-2 border-l-purple-400 border-y-purple-400'
				}`}
			>
				<IconButton
					className={`flex justify-center rounded-l-md rounded-r-none w-[40px] ${
						favoritesFilter ? 'bg-purple-100' : 'hover:bg-purple-900'
					}`}
					icon={faBookmark}
					iconProps={{
						className: `${favoritesFilter ? 'text-white-100' : 'text-purple-400'}`,
						size: 'xs'
					}}
					onClick={handleFavoritesClick}
				/>
			</Box>
			<Box
				className={`rounded-r-md ${
					ignoresFilter
						? 'border-2 bg-purple-100 border-purple-100'
						: favoritesFilter
						? 'border-y-2 border-r-2 border-l-none'
						: 'border-2 border-purple-400'
				}`}
			>
				<IconButton
					className={`flex justify-center rounded-r-md rounded-l-none w-[40px] ${
						ignoresFilter ? 'bg-purple-100 border-4 border-purple-100' : 'hover:bg-purple-900'
					}`}
					icon={faBan}
					iconProps={{
						className: `${ignoresFilter ? 'text-white-100' : 'text-purple-400'}`,
						size: 'xs'
					}}
					onClick={handleIgnoresClick}
				/>
			</Box>
		</ButtonGroup>
	);
};

export const IconButton = ({
	label,
	icon,
	iconProps,
	TypographyComponent,
	typographyProps,
	...props
}) => {
	return (
		<WrapperButtonIcon {...props}>
			<FontAwesomeIcon icon={icon} {...iconProps} />
			{TypographyComponent ? (
				<TypographyComponent {...typographyProps}>{label}</TypographyComponent>
			) : (
				label
			)}
		</WrapperButtonIcon>
	);
};

export const ButtonLinkMobile = ({ label, ...props }) => {
	return <Button {...props}>{label}</Button>;
};

export const ButtonLink = ({ label, destination, handleOnClick, ...props }) => {
	const location = useLocation();
	const theme = useTheme();

	return (
		<>
			{/* Checks if the label is Companies
        if true, use ButtonGroup
    */}
			<Button
				onClick={() => handleOnClick(destination)}
				variant="title3"
				disableRipple
				sx={{
					fontWeight: 500,
					mx: 2,
					textTransform: 'capitalize',
					color: '#020104',
					'&:hover': {
						background: theme.palette.secondary.main,
						color: theme.palette.primary.main
					},
					border: 'none',
					boxShadow: 'none',
					...(location.pathname.startsWith(destination) && {
						position: 'relative',
						color: theme.palette.primary.main,
						'&:after': {
							content: '""',
							position: 'absolute',
							bottom: '-15px',
							width: '100%',
							borderBottom: `3px solid ${theme.palette.primary.main}`
						}
					})
				}}
				{...props}
			>
				{label}
			</Button>
		</>
	);
};

export const ButtonFilter = ({ label, active, all, ...props }) => {
	return (
		<Button
			variant={all ? 'filter-select' : active ? 'filter-active' : 'filter'}
			{...props}
			className="h-[32px] text-[10px] xxl:h-[40px] xxl:text-[14px]"
		>
			{label}
		</Button>
	);
};

export const ButtonFavorite = ({ handleBookmarkRow, fave, styles, size = 'xl' }) => {
	return (
		<WrapperButtonIcon
			sx={{ color: fave === 1 ? '#5337B9' : '#e2e2e2', ...styles }}
			onClick={(event) => handleBookmarkRow(event)}
		>
			<FontAwesomeIcon color="#5337B9" size={size} icon={fave === 1 ? faBookmark : faBookmarked} />
		</WrapperButtonIcon>
	);
};

export const ButtonIgnore = ({ handleIgnoreRow, ignore, styles, size = 'xl' }) => {
	return (
		<WrapperButtonIcon
			sx={{ color: ignore === 1 ? '#5337B9' : '#e2e2e2', ...styles }}
			onClick={(event) => handleIgnoreRow(event)}
		>
			<FontAwesomeIcon color="#5337B9" size={size} icon={ignore === 1 ? faBan : faBan} />
		</WrapperButtonIcon>
	);
};
