import jwtDecode from "jwt-decode";

export const jwtParser = () => {
  const token = localStorage?.getItem("access_token");

  try {
    const data = jwtDecode(token);
    return data;
  } catch(error) {
    return 0;
  }
};
