import React, { useState, useEffect } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	TablePagination,
	useTheme
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { WrapperButtonIcon } from '../../UI/Wrapper';
import { ResponsiveTableHeights } from '../../../tailwind.custom';
import _ from 'lodash';

const DataTableCompanyJobs = ({ tableData, columns }) => {
	const theme = useTheme();
	const [sortedData, setSortedData] = useState(null);
	const [sortDirection, setSortDirection] = useState(true);
	const [transformedData, setTransformedData] = useState();
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const rowsPerPageOptions = [10, 20, 30];

	const handlePageChange = (event, page) => {
		setCurrentPage(page);
		const startIndex = page * rowsPerPage;
		const endIndex = Math.min(startIndex + rowsPerPage, transformedData.length);
		setSortedData(transformedData.slice(startIndex, endIndex));
	};
	const handlerowsPerPageChange = (event) => {
		const newrowsPerPage = parseInt(event.target.value, 10);
		setRowsPerPage(newrowsPerPage);
		setCurrentPage(0);
		const startIndex = 0;
		const endIndex = Math.min(startIndex + newrowsPerPage, transformedData.length);
		setSortedData(transformedData.slice(startIndex, endIndex));
	};

	const onClickHandlerSort = (column) => {
		const newSortedData = _.orderBy(transformedData, [column], [sortDirection ? 'asc' : 'desc']);
		setSortedData(newSortedData);
		setSortDirection((prevValue) => !prevValue);
	};

	const handleRedirect = (url) => {
		if (url.startsWith('https://') || url.startsWith('http://')) {
			// Open the URL in a new tab
			window.open(url, '_blank', 'noopener,noreferrer');
		} else {
			console.error('Invalid URL:', url);
		}
	};

	const isWithinThreeDays = (dateString) => {
		if (!dateString) return false;
		const rowDate = new Date(dateString.split(' ')[0]); // Extract date part and convert to Date object
		const currentDate = new Date();
		const diffInTime = currentDate - rowDate; // Difference in milliseconds
		const diffInDays = diffInTime / (1000 * 60 * 60 * 24); // Convert to days
		return diffInDays >= 0 && diffInDays <= 2; // Check if within 2 days
	};

	useEffect(() => {
		console.log(tableData);
		setTransformedData(tableData);
	}, [tableData]);

	useEffect(() => {
		if (transformedData) {
			setSortedData(transformedData.slice(0, rowsPerPage));
		}
	}, [transformedData, rowsPerPage]);

	useEffect(() => {
		if (Array.isArray(tableData)) {
			const sortedByDate = _.orderBy(tableData, ['latest_date'], ['desc']);
			setTransformedData(sortedByDate);
		} else {
			console.error('Invalid tableData:', tableData);
		}
	}, [tableData]);

	const renderRows = (renderData) => {
		return (
			renderData &&
			renderData.map((row, index) => (
				<TableRow
					key={index}
					sx={{
						backgroundColor: isWithinThreeDays(row.latest_date)
							? theme.palette.green.light
							: 'inherit', // Apply light green if within 3 days
						'&:hover': {
							background: isWithinThreeDays(row.latest_date)
								? theme.palette.green.light
								: theme.palette.grey.hover
						}
					}}
				>
					<TableCell
						className="text-[12px] py-4 px-4 w-[48px]"
						sx={{
							color: '#615D70',
							fontWeight: 500,
							fontSize: '18px'
						}}
					></TableCell>
					{columns.map((column, columnIndex) => (
						<TableCell
							key={columnIndex}
							sx={{
								color: '#615D70',
								fontWeight: 500,
								fontSize: '12px',
								padding: 0
							}}
						>
							{(() => {
								switch (column.key) {
									case 'job_title':
										return (
											<>
												<Box
													className="inline-block cursor-pointer hover:text-accent-100 hover:font-bold"
													key={index}
													onClick={() => handleRedirect(row.job_url)}
												>
													{row[column.key]}
												</Box>
											</>
										);
									case 'latest_date':
										return (
											<Box className="inline-block" key={index}>
												{row[column.key] ? row[column.key].split(' ')[0] : '-'}
											</Box>
										);
									case 'first_date':
										return (
											<Box className="inline-block" key={index}>
												{row[column.key] ? row[column.key].split(' ')[0] : '-'}
											</Box>
										);
									default:
										return (
											<Box className="inline-block" key={index}>
												{row[column.key] !== undefined ? String(row[column.key]) : '-'}
											</Box>
										);
								}
							})()}
						</TableCell>
					))}
				</TableRow>
			))
		);
	};

	return (
		<Box className="flex flex-col mt-[24px]">
			<Box className="flex justify-center">
				{!tableData || tableData.length === 0 ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '200px', // Adjust height as needed
							color: theme.palette.grey.main,
							fontSize: '24px',
							fontWeight: 500,
							backgroundColor: theme.palette.background.default
						}}
					>
						No data available yet.
					</Box>
				) : (
					<Paper sx={{ width: '100%' }}>
						<TableContainer className={`${ResponsiveTableHeights} + border border-grey-300`}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell className="p-0 bg-purple-800 w-[48px]"></TableCell>
										{columns.map((column, index) => (
											<TableCell
												key={index}
												className="text-[14px] font-semibold p-0 py-3 bg-purple-800"
												style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
											>
												<Box className="flex justify-start items-center">
													{column.title}
													<WrapperButtonIcon
														onClickHandler={() => onClickHandlerSort(column.key)}
														color="grey-500"
														className="p-0"
													>
														{column.title !== 'Employment Type' && (
															<FontAwesomeIcon icon={faSort} className="pl-2" />
														)}
													</WrapperButtonIcon>
												</Box>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{sortedData ? renderRows(sortedData) : renderRows(transformedData)}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				)}
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'right',
					alignItems: 'center',
					margin: '5px 0px 5px 5px',
					color: 'red'
				}}
			>
				{tableData && tableData.length > 0 && (
					<TablePagination
						sx={{
							'& .MuiTablePagination-select': {
								minHeight: 'auto',
								fontSize: '15px'
							},
							'& .MuiTablePagination-selectLabel': {
								color: theme.palette.secondary.light
							},
							'& .MuiTablePagination-selectIcon': {
								color: theme.palette.accent.main
							},
							'& .MuiTablePagination-toolbar': {
								color: theme.palette.secondary.light
							},
							'& .MuiTablePagination-actions': {
								color: theme.palette.accent.main
							}
						}}
						component="div"
						count={transformedData ? transformedData.length : 0}
						page={currentPage}
						onPageChange={handlePageChange}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handlerowsPerPageChange}
						rowsPerPageOptions={rowsPerPageOptions}
						showFirstButton
						showLastButton
					/>
				)}
			</Box>
		</Box>
	);
};

export default DataTableCompanyJobs;
