import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NavItems from "./NavItems";
import Settings from "./Settings";
import Drawer from "./Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {location.pathname !== "/" && !location.pathname.includes("admin") && (
        <AppBar
          position="sticky"
          style={{
            background: "#FFFFFF",
            height: "70px",
            boxShadow: "0px 2px 4px 0px #00000040",
          }}
          className="hidden w-full lg:flex xl:flex 2xl:flex justify-center flex-grow px-8 z-10"
        >
          <Box className="w-12/12">
            <Toolbar disableGutters>
              {/* <Drawer /> */}
              <IconButton
                onClick={() => navigate("/results")}
                className="hover:bg-purple-400"
              >
                <img
                  src="/logo/logo-dark.svg"
                  height="30"
                  width="50"
                  alt="OnlineLeads Logo"
                />
                <Typography variant="h5" className="text-accent-100" noWrap>
                  OnlineLeads
                </Typography>
              </IconButton>

              <NavItems />
              <Settings />
            </Toolbar>
          </Box>
        </AppBar>
      )}
      {location.pathname !== "/" && (
        <AppBar
          position="sticky"
          style={{
            background: "#FFFFFF",
            height: "80px",
            boxShadow: "0px 2px 4px 0px #00000040",
            zIndex: 1,
          }}
          className="block w-full lg:hidden xl:hidden 2xl:hidden 3xl:hidden"
        >
          <Toolbar
            disableGutters
            className="flex items-center w-full h-full justify-between px-6"
          >
            <Box className="flex">
              <Drawer />
              <IconButton
                onClick={() => navigate("/results")}
                className="hover:bg-purple-400"
              >
                <img
                  src="/logo/logo-dark.svg"
                  height="20"
                  width="40"
                  alt="OnlineLeads Logo"
                />
                <Typography
                  variant="boldLink"
                  className="text-accent-100 text-[18px]"
                  noWrap
                >
                  OnlineLeads
                </Typography>
              </IconButton>
            </Box>

            <Settings />
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
export default Navigation;
