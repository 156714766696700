import { Box, Typography } from '@mui/material';
import Header from './Header';
import Table from './Table';
import Card from '../UI/Cards';
import { ButtonFavorite } from '../UI/Button';
import { useState, useEffect } from 'react';
import { useGetFavoritesQuery } from '../../features/favorites';
import Loading from '../Common/Loading';
import { useNavigate } from 'react-router-dom';

// Card component
const CardWrapper = ({ companyName, noOfJobsListed }) => {
	const [toggleCard, setToggleCard] = useState(false);

	const handleToggleCard = () => setToggleCard((value) => !value);

	return (
		<Card onClickCardHandler={handleToggleCard}>
			<Content companyName={companyName} noOfJobsListed={noOfJobsListed} toggleCard={toggleCard} />
		</Card>
	);
};

// Content component
const Content = ({ companyName, noOfJobsListed = 123, toggleCard }) => {
	return (
		<Box>
			<Box className={`flex justify-between items-center${toggleCard && `items-start flex-col`} `}>
				{toggleCard && <Typography variant="card">Company</Typography>}
				<Box className="flex items-center">
					<ButtonFavorite fave={0} size="sm" styles={{ pl: 0 }} />
					<Typography variant="card">{companyName}</Typography>
				</Box>
				{toggleCard && <Typography variant="card">No. of Job Listings</Typography>}
				<Typography variant="card" className={`font-light ${!toggleCard && `self-center`}`}>
					{noOfJobsListed}
				</Typography>
			</Box>
		</Box>
	);
};

export default function Index() {
	const { data: results, isLoading, isError, refetch } = useGetFavoritesQuery();
	const [processedData, setProcessData] = useState(null);
	const [selectedSearchResult, setSelectedSearchResult] = useState(null);
	const accessToken = localStorage?.getItem('access_token');
	const navigate = useNavigate();

	useEffect(() => {
		if (!accessToken) {
			// Redirect to landing page if token is missing
			navigate('/');
		}

		refetch();
	}, [accessToken, navigate, refetch]);

	return (
		<Box className="flex flex-col items-center h-full w-full mx-[40px] my-[32px] xxl:m-[48px]">
			{!isError && !isLoading && (
				<Box className="w-full">
					<Header
						results={results}
						setProcessData={setProcessData}
						setSelectedSearchResult={setSelectedSearchResult}
					/>
					<Box className="hidden lg:block">
						<Table
							results={processedData ? processedData : results}
							selectedSearchResult={selectedSearchResult}
							setSelectedSearchResult={setSelectedSearchResult}
						/>
					</Box>
					<Box className="block lg:hidden">
						{results &&
							results.map(({ company, comp_id }) => (
								<CardWrapper key={comp_id} companyName={company} noOfJobsListed={comp_id} />
							))}
					</Box>
				</Box>
			)}
			{isLoading && <Loading />}
		</Box>
	);
}
