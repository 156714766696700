import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonLink } from "../UI/Button";

export const pages = [
  // { label: "Home", destination: "/home" },
  { label: "Search Terms", destination: "/search-terms" },
  { label: "Results", destination: "/results" },
  // { label: "Favorites", destination: "/favorites" },
  { label: "Companies", destination: "/companies" },
  //{ label: "Admin", destination: "/admin" },
];

export default function NavItems() {
  const navigate = useNavigate();

  const handleOnClick = (destination) => navigate(destination);

  return (
    <Box className="flex justify-center items-center flex-grow">
      {pages.map(({ label, destination }, index) => (
        <ButtonLink
          className="text-[15px]"
          label={label}
          destination={destination}
          key={index}
          handleOnClick={handleOnClick}
        />
      ))}
    </Box>
  );
}
