import { Box, Paper, Typography } from '@mui/material';
import { InputSearch } from '../UI/Input';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCompaniesQuery } from 'features/companies';

export default function Index() {
	const { data } = useGetCompaniesQuery();
	const [open, setOpen] = useState(false);
	const [results, setResults] = useState();
	const [initializing, setInitializing] = useState(true);
	const [selectedIndex, setSelectedIndex] = useState(-1); // Track highlighted result
	const navigate = useNavigate();
	const accessToken = localStorage?.getItem('access_token');

	const handleInput = (value) => {
		setOpen(value ? true : false);
		setSelectedIndex(-1); // Reset selection on new input
		setResults(() => {
			return data
				?.filter((item) => {
					return item && item.comp_name && item.comp_name.toLowerCase().includes(value);
				})
				.slice(0, 5); // Limiting to at most 5 results
		});
	};

	// Memoize handleClick using useCallback
	const handleClick = useCallback(
		(item) => {
			navigate(`/companies/${item.comp_id}`);
		},
		[navigate] // Dependencies: Only re-create handleClick if navigate changes
	);

	useEffect(() => {
		if (!accessToken) {
			navigate('/');
		}
	}, [accessToken, navigate]);

	// Move handleKeyDown function inside useEffect
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (!results || results.length === 0) return;

			// Prevent default behavior for ArrowUp and ArrowDown to avoid caret movement
			if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
				event.preventDefault();
			}

			if (event.key === 'ArrowDown') {
				// Navigate down
				setSelectedIndex((prevIndex) => (prevIndex + 1) % results.length);
			} else if (event.key === 'ArrowUp') {
				// Navigate up
				setSelectedIndex((prevIndex) => (prevIndex - 1 + results.length) % results.length);
			} else if (event.key === 'Enter' && selectedIndex >= 0) {
				// Navigate to selected result
				handleClick(results[selectedIndex]);
			}
		};

		const inputBox = document.querySelector('input');
		inputBox?.addEventListener('keydown', handleKeyDown);

		return () => {
			inputBox?.removeEventListener('keydown', handleKeyDown);
		};
	}, [results, selectedIndex, handleClick]); // Update listener when results or selectedIndex changes

	useEffect(() => {
		if (data && data.length > 0) {
			setInitializing(false);
		}
	}, [data, setInitializing]);

	return (
		<Box className="flex justify-center items-center h-auto w-full mx-10 my-20 xxl:m-48">
			<Box className="flex flex-col justify-center items-center w-full lg:w-[70%] 2xl:w-[60%]">
				<Typography className="text-3xl mb-10 text-purple-300 font-medium">Companies</Typography>
				<InputSearch
					placeholder={initializing ? 'Loading...' : 'Search'}
					sx={{
						'& .MuiInputBase-root': {
							height: '48px',
							fontSize: '18px',
							fontWeight: 400
						}
					}}
					onChange={(event) => {
						handleInput(event.target.value);
					}}
					disabled={initializing}
				/>
				{open && (
					<Paper className="flex flex-col w-full">
						{results?.map((item, index) => (
							<Typography
								key={index}
								onClick={() => handleClick(item)}
								variant="paragraph"
								className={`cursor-pointer px-[20px] py-[14px] hover:bg-grey-900 ${
									index === selectedIndex ? 'bg-grey-900 text-white' : ''
								}`} // Highlight selected item
							>
								{item.comp_name}
							</Typography>
						))}
					</Paper>
				)}
			</Box>
		</Box>
	);
}
