import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { WrapperButtonIcon } from "../UI/Wrapper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleNav } from "../../features/navigation";
import { pages } from "./NavItems";
import Button from "../UI/Button";

export default function SideNavMoible() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideNavRef = useRef(null);

  const handleClickOutside = (event) => {
    if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      // Clicked outside the SideNavMoible, handle the click or close the component
      dispatch(toggleNav());
    }
  };

  useEffect(() => {
    // Add click event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove the click event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = (destination) => {
    navigate(destination);
    dispatch(toggleNav());
  };

  return (
    <Box
      ref={sideNavRef}
      className="w-10/12 h-full bg-white-200 border-r-[1.5px] border-grey-300"
    >
      <Box className="flex flex-col justify-between h-full w-full">
        <Box className="border-b-[1.5px] border-grey-300">
          <WrapperButtonIcon
            className="m-6"
            onClick={() => navigate("/results")}
          >
            <img
              src="/logo/logo-dark.svg"
              height="30"
              width="50"
              alt="OnlineLeads Logo"
            />
            <Typography variant="h5" className="text-accent-100" noWrap>
              OnlineLeads
            </Typography>
          </WrapperButtonIcon>
        </Box>
        <Box className="m-4">
          <Typography variant="headline7" color="primary">
            NAVIGATION
          </Typography>
        </Box>

        <Box className="flex flex-col items-center justify-evenly h-2/6 bg-purple-900 rounded m-4">
          {pages.map(({ label, destination }, index) => (
            <Button
              className="text-[18px] justify-start w-full text-purple-600 font-light normal-case t shadow-none bg-purple-900 h-[56px] px-8 py-4"
              label={label}
              destination={destination}
              key={index}
              onClick={() => handleOnClick(destination)}
            />
          ))}
        </Box>

        <Box className="flex flex-col p-4 border-b-[1.5px] border-grey-300">
          {/* <Typography variant="headline7" color="primary">
            SEARCH TERMS
          </Typography>
          <WrapperButtonIcon className="flex align-start justify-start text-purple-600 border-none shadow-none normal-case m-4">
            <FontAwesomeIcon icon={faPen} />
            <Typography className="ml-4 text-[18px] font-light">
              Edit
            </Typography>
          </WrapperButtonIcon> */}
        </Box>
        {/* <Box className="flex flex-col m-4">
          <WrapperButtonIcon className="flex align-start justify-start text-purple-600 border-none shadow-none normal-case m-4">
            <FontAwesomeIcon icon={faCog} />
            <Typography className="ml-4 text-[18px] font-light">
              Account Settings
            </Typography>
          </WrapperButtonIcon>
        </Box> */}
        <Typography className="flex self-center text-purple-500 font-semibold text-[18px] py-5">
          © 2023 OnlineLeads
        </Typography>
      </Box>
    </Box>
  );
}
