import React from 'react';
import Results from './Results';
import { Box, Typography } from '@mui/material';
import { useGetResultsQuery } from '../../features/results';
import { useGetSearchTermsQuery } from '../../features/searchTerms';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../Common/Loading';
import { ButtonFilter, FavoritesIgnoresButton } from '../UI/Button';
import { useState, useEffect } from 'react';
import MobileView from './MobileView';
import Cookies from 'js-cookie';
import TipsDialog from './TipsDialog';

export default function ResultsComponent() {
	const { data: results, isLoading, isError, refetch } = useGetResultsQuery();
	const { data: currentSearchTerms } = useGetSearchTermsQuery();
	const [filterButton, setFilterButton] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const navigate = useNavigate();
	const location = useLocation();
	const [tipsDialog, setTipsDialog] = useState(null);
	const accessToken = localStorage?.getItem('access_token');
	const [selectAllFilter, setSelectAllFilter] = useState(false);
	const [favoritesFilter, setFavoritesFilter] = useState(false);
	const [ignoresFilter, setIgnoresFilter] = useState(false);

	const handleCloseTipsDialog = () => {
		setTipsDialog(false);
		Cookies.set('persistHideTips', true, { expires: 365 });
	};

	useEffect(() => {
		if (!accessToken) {
			// Redirect to landing page if token is missing
			navigate('/');
		}

		refetch();

		const showTips = Cookies.get('showTips');
		const persistHideTips = Cookies.get('persistHideTips');

		if (persistHideTips === 'true') {
			setTipsDialog(false);
		} else if (showTips === 'false') {
			setTipsDialog(false);
		} else if (showTips === 'true' && persistHideTips === undefined && accessToken) {
			setTipsDialog(true);
		} else {
			setTipsDialog(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentSearchTerms) {
			const updatedFilterButtonState = currentSearchTerms.map((data) => ({
				name: data.combined_search_term,
				selected: true
			}));
			setFilterButton(updatedFilterButtonState);

			// If search terms are empty, redirect to Search Terms page
			if (currentSearchTerms.length === 0) {
				navigate('/search-terms', { state: { showErrorNotificationFromResults: true } });
			}
		}
	}, [currentSearchTerms, navigate]);

	const onClickFilterHandler = (event) => {
		const { name } = event.currentTarget;
		setFilterButton((prevValue) =>
			prevValue.map((data) => {
				return data.name === name ? { ...data, selected: !data.selected } : data;
			})
		);
	};

	const onClickSelectAllHandler = () => {
		setFilterButton((prevValue) =>
			prevValue.map((data) => ({
				...data,
				selected: selectAllFilter ? false : true // Toggle selected based on selectAllFilter
			}))
		);
		setSelectAllFilter(!selectAllFilter); // Toggle the selectAllFilter state
	};

	const handleFavoritesClick = () => {
		// Toggle the favorites filter state
		setFavoritesFilter(!favoritesFilter);
		setIgnoresFilter(false); // Ensure ignoresFilter is turned off when favoritesFilter is toggled
	};

	const handleIgnoresClick = () => {
		setIgnoresFilter(!ignoresFilter);
		setFavoritesFilter(false);
	};

	useEffect(() => {
		if (results && filterButton) {
			// Step 1: Filter the results based on the selected search terms
			let filteredData = results.filter((data) => {
				const filterItem = filterButton.find((filter) => filter.name === data.search_terms);
				return filterItem && filterItem.selected;
			});
	
			// Step 2: If favoritesFilter is true, filter the results where `fave === 1`
			if (favoritesFilter) {
				filteredData = filteredData.filter((data) => data.fave === 1);
			}
	
			// Step 3: Set the filtered results
			setFilteredResults(filteredData);
	
			// Step 4: Check if all items have `selected` set to true (for "Select All")
			const allSelected = filterButton.every((item) => item.selected);
			setSelectAllFilter(allSelected);
		}
	
		// Step 5: Handle location state for default search term selection (if provided)
		if (
			location.state &&
			(location.state.searchTermFieldSelected || location.state.searchTermFieldSelected === 0)
		) {
			const searchTermFieldSelected = location.state.searchTermFieldSelected;
			setFilterButton((prevValue) => {
				const updatedFilterButton = prevValue?.map((item, index) => {
					index === searchTermFieldSelected ? (item.selected = true) : (item.selected = false);
					return {
						...item
					};
				});
				return updatedFilterButton;
			});
			location.state.searchTermFieldSelected = null;
		}
	}, [results, filterButton, favoritesFilter, location.state]); // Dependencies include results, filterButton, favoritesFilter, and location.state	

	return (
		<Box className="w-full mx-[40px] my-[32px] xxl:m-[48px]">
			{false && <TipsDialog handleCloseTipsDialog={handleCloseTipsDialog} />}
			{/* {tipsDialog && <TipsDialog handleCloseTipsDialog={handleCloseTipsDialog} />} */}
			{/* {!isError && !isLoading && !tipsDialog && ( */}
			{!isError && !isLoading && true && (
				<Box className="hidden lg:flex xl:flex 2xl:flex items-center justify-start w-full">
					<Box className="w-full">
						<Typography className="text-[25px] text-purple-300 font-medium">Results</Typography>
						<Box className="flex justify-between items-center mb-4">
							<Box className="flex items-center gap-x-6">
								<Typography variant="title3"> Search Terms: </Typography>
								{filterButton &&
									filterButton.map(({ name, selected }, index) => {
										return (
											<ButtonFilter
												onClick={onClickFilterHandler}
												name={name}
												label={name}
												active={selected}
												key={index}
											/>
										);
									})}
								<ButtonFilter
									onClick={onClickSelectAllHandler}
									label={selectAllFilter ? 'UNSELECT ALL' : 'SELECT ALL'}
									all={true}
								/>
							</Box>
							<FavoritesIgnoresButton
								favoritesFilter={favoritesFilter}
								ignoresFilter={ignoresFilter}
								handleFavoritesClick={handleFavoritesClick}
								handleIgnoresClick={handleIgnoresClick}
							/>
						</Box>

						<Results results={filteredResults} isLoading={isLoading} refetch={refetch} isError={isError} />
					</Box>
				</Box>
			)}
			{isLoading && !tipsDialog && <Loading />}
			<Box className="flex lg:hidden xl:hidden 2xl:hidden flex-col w-full">
				{isLoading ? <Loading /> : <MobileView results={results} />}
			</Box>
		</Box>
	);
}
