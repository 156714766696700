import { useState } from "react";
import { Typography, Box } from "@mui/material";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WrapperButtonIcon } from "../UI/Wrapper";
import { ResultCard } from "../UI/Cards";
import OverlayFilter from "../Common/OverlayFilter";
import { handleBookmarkToggle } from "../../helpers/utils/api";
import CompanyDialog from "./CompanyDialog";

export default function ResultsMobileView({
  transformedData,
  setTransformedData,
  results,
}) {
  const [filterDialog, setFilterDialog] = useState(false);
  const [companyDialog, setOpenDialog] = useState({ open: false, name: "" });

  const filterOnClickHandler = () => setFilterDialog((value) => !value);

  const handleBookmarkRow = async (event, rowIndex, comp_id, fave) => {
    event?.stopPropagation();
    handleBookmarkToggle(comp_id, fave);
    setTransformedData((prevValue) => {
      const updatedData = prevValue.map((data, index) => {
        if (data.comp_id === comp_id) {
          setOpenDialog((data) => ({ ...data, fave: data.fave === 1 ? 0 : 1 }));
          return { ...data, fave: data.fave === 1 ? 0 : 1 };
        }
        return data;
      });
      return updatedData;
    });
  };

  const handleCloseDialog = () =>
    setOpenDialog((data) => ({ ...data, open: false }));

  const handleOpenDialog = (event, props) => {
    event.stopPropagation();
    setOpenDialog((data) => ({ ...data, ...props, open: true }));
  };
  return (
    <Box className="w-full">
      <CompanyDialog
        open={companyDialog.open}
        handleClose={handleCloseDialog}
        handleBookmarkRow={handleBookmarkRow}
        setData={setTransformedData}
        setDialog={setOpenDialog}
        {...companyDialog}
      />
      <Typography variant="title3" className="text-purple-300">
        Search Terms:
        <WrapperButtonIcon color="accent" onClick={filterOnClickHandler}>
          <FontAwesomeIcon icon={faFilter} />
        </WrapperButtonIcon>
      </Typography>
      {transformedData &&
        transformedData.length > 1 &&
        transformedData.map((result, index) => {
          return (
            <ResultCard
              index={index}
              key={index}
              handleBookmarkRow={handleBookmarkRow}
              handleCompanyDialog={(event) => handleOpenDialog(event, result)}
              {...result}
            />
          );
        })}
      <OverlayFilter
        visibility={filterDialog}
        closeOverlayFilter={filterOnClickHandler}
        setTransformedData={setTransformedData}
        results={results}
      />
    </Box>
  );
}
