import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBookmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useGetCompanyQuery } from 'features/specificCompany';
import { useGetDescriptionsQuery } from 'features/descriptions';
import { useNavigate } from 'react-router-dom';
import { handleBookmarkToggle } from 'helpers/utils/api';
import { useEffect, useState } from 'react';
// import CompanyDetails from './CompanyDetails';
import Table from './Table';
import Button, { NotesButton } from '../../UI/Button';
import { Headline6 } from '../../UI/Typography';
import NotesDialog from './NotesDialog';

export default function Index() {
	const [mongoId, setMongoId] = useState();
	const { data: company, isLoading: isCompanyLoading } = useGetCompanyQuery();
	const { data: descriptions, isLoading: isDescriptionsLoading } = useGetDescriptionsQuery(mongoId);
	const navigate = useNavigate();
	const [isBookmarked, setIsBookmarked] = useState();
	const [jobs, setJobs] = useState([]);
	const [isDataReady, setIsDataReady] = useState(false); // Flag to track when all data is ready
	const [isNotesEmpty, setIsNotesEmpty] = useState(false);
	const [notesDialog, setNotesDialog] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [notes, setNotes] = useState("");
	const accessToken = localStorage?.getItem('access_token');

	const goBack = () => {
		navigate(-1);
	};

	const handleBookmark = (id, fave) => {
		if (handleBookmarkToggle(id, fave)) {
			setIsBookmarked(fave === 1 ? 0 : 1);
		}
	};

	const handleOpenNotesDialog = (edit) => {
		setEditMode(edit);
		setNotesDialog(true);
	};
	const handleCloseNotesDialog = () => {
		setNotesDialog(false);
	};

	useEffect(() => {
		if (!accessToken) {
			navigate('/');
		}
	}, [accessToken, navigate]);

	useEffect(() => {
		if (company) {
			setIsBookmarked(company?.fave);
			setMongoId(company?.mongo_id);
		}
		
		setNotes(company?.notes);
		setIsNotesEmpty((!company?.notes || (company?.notes === "")) ? true : false);
	}, [company]);

	useEffect(() => {
		if (descriptions) {
			setJobs(descriptions.jobs);
			setIsDataReady(true); // Mark data as ready when jobs are available
		}
	}, [descriptions]);

	// Conditionally render content when both queries are loaded
	if (isCompanyLoading && isDescriptionsLoading && !isDataReady) {
		// Optionally show a loading indicator while data is being fetched or rows are still being processed
		return (
			<Box className="flex justify-center items-center h-screen w-full">
				<Typography>Loading...</Typography>
			</Box>
		);
	}

	return (
		<>
			{notesDialog && (
				<NotesDialog
					handleCloseNotesDialog={handleCloseNotesDialog}
					editMode={editMode}
					setNotesMain={setNotes}
					setIsNotesEmpty={setIsNotesEmpty}
					comp_notes={notes}
					comp_id={company?.comp_id}
				/>
			)}
			<Box className="flex flex-col h-auto w-full mx-[72px] my-[48px] xxl:m-48s">
				<Box className="flex justify-between items-center">
					<Box className="flex justify-start items-center">
						<FontAwesomeIcon
							icon={faArrowLeft}
							className="pl-2"
							style={{
								cursor: 'pointer',
								color: '#5337B9',
								fontSize: '32px'
							}}
							onClick={goBack}
						/>
						<Typography variant="title1" className="ml-[24px]">
							{company?.comp_name}
						</Typography>
						<FontAwesomeIcon
							icon={faBookmark}
							className="pl-2"
							style={{
								cursor: 'pointer',
								color: isBookmarked === 1 ? '#5337B9' : '#e2e2e2',
								fontSize: '32px',
								marginLeft: '8px'
							}}
							onClick={() => handleBookmark(company.comp_id, isBookmarked)}
						/>
					</Box>
					<Box className="w-auto h-[48px]" onClick={() => handleOpenNotesDialog(isNotesEmpty ? false : true)}>
						{isNotesEmpty ? (
							<Button
								variant="secondary"
								label={'Add Notes'}
								TypographyComponent={Headline6}
								startIcon={<FontAwesomeIcon icon={faPlus} />}
							/>
						) : (
							<NotesButton
								label="Notes:"
								notes={notes}
							/>
						)}
					</Box>
				</Box>
				{/* <CompanyDetails company={company} /> */}
				{isDataReady && (
					<Table
						results={jobs} // Render the table only when rows are ready
						isLoading={isCompanyLoading && isDescriptionsLoading}
					/>
				)}
			</Box>
		</>
	);
}
